import * as React from 'react';
import {
  createFolder,
  getListpath,
  updateFolder,
  deleteFolder,
  uploadMultipleFile,
  downloadFolder,
  resetPasswords,
} from '../../service/files';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_LOCALHOST, PATH_URL } from '../../service/config';
import '../../access/main.css';
import { showNotification } from '../../service/notifications';

export default function Upload() {
  const [isUpload, setIsUpload] = useState(false);
  const [names, setNames] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [nameFolder, setNameFolder] = useState();
  const [oldNameFolder, setOldNameFolder] = useState();
  const [fileUploads, setFileUploads] = useState();
  const [paths, setPaths] = useState();

  // GET LIST DATA
  const getData = () => {
    let promise;
    promise = getListpath('');
    promise
      .then((response) => {
        setNames(response.data);
      })
      .catch((error) => {
        setNames([]);
        console.log(error);
      });
  };
  // END GET LIST DATA

  useEffect(() => {
    const userLogin = JSON.parse(localStorage.getItem('usertoken'));
    if (!userLogin) {
      window.location.replace(`${API_LOCALHOST}`);
    }
    getData();
  }, []);

  // CREATE NEW FOLDER
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setNameFolder('');
    setShow(true);
  };
  const create = () => {
    if (nameFolder !== null && nameFolder !== undefined) {
      createFolder(nameFolder, '')
        .then((response) => {
          setShow(false);
          showNotification('Create new folder success!', 'success');
          getData();
        })
        .catch((error) => {
          console.log(error);
          showNotification('Create new folder fail!', 'danger');
        });
    }
  };
  // Change Name Folder
  const changeNameFolder = (event) => {
    setNameFolder(event.target.value);
  };
  // END CREATE NEW FOLDER

  const subFolder = (f) => {
    if (f.isFolder) {
      window.location.replace('/sub_folder/' + f.name);
      localStorage.setItem('path', f.path);
    } else window.open(f.path);
  };

  // UPDATE FOLDER
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = (f) => {
    setOldNameFolder(f.name);
    setNameFolder(f.name);
    setShowUpdate(true);
  };
  const updateFolders = () => {
    if (nameFolder !== null && nameFolder !== undefined) {
      updateFolder(oldNameFolder, nameFolder)
        .then((response) => {
          setOldNameFolder();
          setShowUpdate(false);
          showNotification('Update folder success!', 'success');
          getData();
        })
        .catch((error) => {
          console.log(error);
          showNotification('Update folder fail!', 'danger');
        });
    }
  };
  // END UPDATE FOLDER

  // UPLOAD MULTIPLE FILES
  const [showMutipleUpload, setshowMutipleUpload] = useState(false);
  const handleClosePopupUpload = () => setshowMutipleUpload(false);
  const handleShowPopupUpload = () => setshowMutipleUpload(true);
  const uploadMultipleFiles = () => {
    setIsUpload(true);
    if (!isUpload) {
      uploadMultipleFile(fileUploads, '')
        .then((response) => {
          setshowMutipleUpload(false);
          getData();
          setIsUpload(false);
          setFileUploads(null);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(getData());
    }
  };
  const chooseUploadFile = (e) => {
    setFileUploads(e.target.files);
  };
  // END MULTIPLE UPLOAD FILES

  // DOWNLOAD FILE ZIP
  const [showDownloadProgress, setshowDownloadProgress] = useState(false);
  const handleShowDownload = () => setshowDownloadProgress(true);
  const handleCloseDownload = () => setshowDownloadProgress(false);
  const downloadFileZip = (path, name) => {
    fetch(path, {
      method: 'GET',
      // mode: 'no-cors',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name); //or any other extension
          document.body.appendChild(link);
          link.click();
          setshowDownloadProgress(false);
          showNotification('Download file successfully!', 'success');
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getData();
      });
  };
  // END DOWNLOAD FILE ZIP

  // ZIP FILE
  const zipFile = async () => {
    let listzip = names.filter((name) => name.isChecked === true);
    let result = await downloadFolder(listzip);
    downloadFileZip(result.data.path, result.data.name);
  };
  // END ZIP FILE

  
  // DOWNLOAD ALL
  const downloadAll = async () => {
    const users = JSON.parse(localStorage.getItem('usertoken'));
    const path = PATH_URL + users.id;
    let lstDownload = [
      {
        path: path,
        name: users.id,
        isFolder: true,
      },
    ];

    let result = await downloadFolder(lstDownload);
    downloadFileZip(result.data.path, result.data.name);
  };
  // DOWNLOAD ALL

  // DOWNLOAD
  const download = (f) => {
    fetch(f.path, {
      method: 'GET',
      // mode: 'no-cors',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', f.name); //or any other extension
          document.body.appendChild(link);
          link.click();
          setshowDownloadProgress(false);
          showNotification('Download file successfully!', 'success');
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // END DOWNLOAD

  // SELECT ALL
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'allSelect') {
      let tempItem = names.map((filename) => {
        return { ...filename, isChecked: checked };
      });
      setIsCheck(true);
      setNames(tempItem);
    } else {
      let tempItem = names.map((filename) => {
        if (filename.name === name) {
          return {
            ...filename,
            isChecked: checked,
          };
        }
        setIsCheck(true);
        return {
          ...filename,
        };
      });
      let flag = 0;
      tempItem.map((item) => {
        if (item.isChecked) {
          flag = 1;
        }
      });
      if (!flag) {
        setIsCheck(false);
      } else {
        setIsCheck(true);
      }
      setNames(tempItem);
    }
  };
  // END SELECT ALL

  // DELETE SELECTE
  const [showDeleteSelect, setShowDeletSelect] = useState(false);
  const handleCloseDeleteSelect = () => setShowDeletSelect(false);
  const handleShowDeleteSelect = (names) => {
    setShowDeletSelect(true);
    setPaths(names);
  };
  const handleDeleteSelect = () => {
    let listDelete = names.filter((item) => {
      return item.isChecked === true;
    });
    if (listDelete.length > 0) {
      deleteFolder(listDelete)
        .then((response) => {
          setShowDeletSelect(false);
          showNotification('Delete folder success!', 'success');
          getData();
        })
        .catch((error) => {
          showNotification('Delete folder fail!', 'danger');
          getData();
        });
    }
  };
  // END DELETE SELECT

  // DELETE FOLDER
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (names) => {
    setShowDelete(true);
    setPaths(names);
  };
  const deleteFolderFile = () => {
    deleteFolder([paths])
      .then((response) => {
        setShowDelete(false);
        showNotification('Delete folder success!', 'success');
        getData();
      })
      .catch((error) => {
        console.log(error);
        showNotification('Delete folder fail!', 'danger');
      })
      .finally(getData());
  };
  // END DELETE FOLDER

  // DELETE ALL FILE AND FOLDER
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const handleCloseDeleteAll = () => setShowDeleteAll(false);
  const handleShowDeleteAll = (names) => {
    setShowDeleteAll(true);
    setPaths(names);
  };

  const deleteAll = () => {
    let lstDelete = [];
    names.forEach((e) => {
      const item = {
        path: e.path,
      };
      lstDelete.push(item);
    });
    console.log(lstDelete);
    deleteFolder(lstDelete)
      .then((response) => {
        setTimeout(function () {
          window.location.reload();
        }, 500);
        setShowDeleteAll(false);
        showNotification('Delete File success', 'success');
        getData();
      })
      .catch((error) => {
        console.log(error);
        showNotification('Delete File fail!', 'danger');
      });
  };
  // DELETE ALL FILE AND FOLDER

  // RESET PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [showResetPassword, setshowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setshowResetPassword(false);
  const handleShowResetPassword = () => {
    setshowResetPassword(true);
  };

  // Hide PassWord
  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  const resetPassword = () => {
    resetPasswords(currentPassword, newPassword)
      .then((response) => {
        setshowResetPassword(false);
        showNotification('Update password success', 'success');
      })
      .catch((error) => {
        console.log(error);
        showNotification('Update password fail', 'danger');
      });
  };

  const changeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const changeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  // END RESET PASSWORD

  // LOGOUT
  const logout = () => {
    localStorage.clear();
    showNotification('Logout success !', 'success');
    window.location.replace('/');
  };

  return (
    <>
      <div className="container" style={{ minHeight: '100vh' }}>
        <h2>Folder</h2>
        <div className="row col-sm-auto">
          <div className="col-sm-8">
            <button className="btn btn-primary" onClick={() => handleShow()}>
              New Folder
            </button>
            <button
              className="btn btn-success"
              style={{ marginLeft: '5px' }}
              onClick={() => handleShowPopupUpload()}
            >
              Upload File
            </button>
            {isCheck && (
              <button
                className="btn btn-danger ml-2"
                onClick={() => handleShowDeleteSelect(names)}
                hidden={
                  !names.some((filename) =>
                    filename.isChecked === true ? true : false
                  )
                }
              >
                Delete Selected
              </button>
            )}
            {isCheck && (
              <button
                className="btn btn-information ml-2"
                onClick={() => {
                  zipFile(); handleShowDownload()
                }}
                hidden={
                  !names.some((filename) =>
                    filename.isChecked === true ? true : false
                  )
                }
              >
                Download Selected
              </button>
            )}
            {!isCheck && (
              <button
                className="btn btn-danger ml-2"
                onClick={() => handleShowDeleteAll()}
              >
                Delete All
              </button>
            )}
            {!isCheck && (
              <button
                className="btn btn-information ml-2"
                onClick={() => downloadAll()}
              >
                Download All
              </button>
            )}
          </div>
          <div className="col-sm-4" style={{ textAlign: 'right' }}>
            <button
              className="btn btn-info"
              style={{ marginRight: '5px' }}
              onClick={() => handleShowResetPassword()}
            >
              Reset password
            </button>
            <button className="btn btn-secondary" onClick={() => logout()}>
              Sign out <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </div>
        <table className="table bordered mt-4">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="allSelect"
                  id="myCheckbox"
                  checked={
                    !names.some((filename) => filename?.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </th>
              <th scope="col">ID</th>
              <th scope="col-2">Name</th>
              <th scope="col-2" style={{ textAlign: 'center' }}>
                Download Count
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {names ? (
              names.map((f, index) => (
                <tr key={f.id}>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name={f.name}
                      checked={f?.isChecked || false}
                      onChange={handleChange}
                    />
                  </td>
                  <td scope="row">{index + 1}</td>
                  <td
                    scope="row"
                    onDoubleClick={() => subFolder(f)}
                    style={{ cursor: 'pointer' }}
                  >
                    {f.isFolder === true ? (
                      <i className="fa-regular fa-folder"></i>
                    ) : (
                      <i className="fa fa-paperclip"></i>
                    )}{' '}
                    {f.name}
                  </td>
                  <td
                    scope="row"
                    style={{
                      textAlign: 'center',
                      color: 'green',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    {f.download_count}
                  </td>
                  <td>
                    {f.isFolder === true ? (
                      <i
                        className="fa fa-edit"
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => handleShowUpdate(f)}
                      ></i>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {f.isFolder === false ? (
                      <i
                        className="fa fa-download"
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => download(f)}
                      ></i>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <i
                      className="fa fa-trash"
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => handleShowDelete(f)}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>

        {/* START CREATE FOLDER */}
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header>
            <Modal.Title>Create Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name Folder</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'left' }}
                name="nameFolder"
                id="nameFolder"
                onChange={changeNameFolder}
                value={nameFolder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={nameFolder ? false : true}
              onClick={() => create()}
            >
              Save
            </Button>
            <Button variant="secondary" onClick={() => handleClose()}>
              <i></i>Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END CREATE FOLDER */}

        {/* START UPDATE */}
        <Modal show={showUpdate} onHide={handleCloseUpdate} animation={false}>
          <Modal.Header>
            <Modal.Title>Update Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name Folder</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'left' }}
                value={nameFolder}
                name="nameFolder"
                id="nameFolder"
                onChange={changeNameFolder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => updateFolders()}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => handleCloseUpdate()}>
              <i></i> Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END UPDATE */}

        {/* START DELETE */}
        <Modal show={showDelete} onHide={handleCloseDelete} animation={false}>
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={() => deleteFolderFile()}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => handleCloseDelete()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END DELETE */}

        {/* START DELETE SELECT */}
        <Modal
          show={showDeleteSelect}
          onHide={handleCloseDeleteSelect}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={() => handleDeleteSelect()}>
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleCloseDeleteSelect()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END DELETE SELECT */}

        {/* START DELETE ALL */}
        <Modal
          show={showDeleteAll}
          onHide={handleCloseDeleteAll}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={() => deleteAll()}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => handleCloseDeleteAll()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END DELETE ALL */}

        {/* START DOWNLOAD MESSAGE */}
        <Modal
          show={showDownloadProgress}
          onHide={handleCloseDownload}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Downloading Files...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src='https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif' style={{width: '100px', height: '100px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} alt='download loading' />
              <label for="html-files" style={{ width: '100%' }}>
                Download in progress. Please wait a minute...
              </label>
              
          </Modal.Body>
        </Modal>
        {/* END DOWNLOAD MESSAGE */}
        
        {/* START MULTIPLE UPLOAD */}
        <Modal
          show={showMutipleUpload}
          onHide={handleClosePopupUpload}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Upload Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form method="POST" enctype="multipart/form-data">
              <label for="html-files" style={{ width: '100%' }}>
                Select Files Maximum 2GB:
              </label>
              <input
                type="file"
                id="html-files"
                name="html-files[]"
                multiple
                onChange={(e) => chooseUploadFile(e)}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={fileUploads ? false : true}
              onClick={() => uploadMultipleFiles()}
            >
              {isUpload ? 'Uploading...' : 'Upload'}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setshowMutipleUpload(false)}
            >
              <i></i>Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END MULTIPLE UPLOAD */}

        {/* START RESET PASSWORD */}
        <Modal
          show={showResetPassword}
          onHide={handleCloseResetPassword}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Current Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="**********"
                value={currentPassword}
                onChange={changeCurrentPassword}
                defaultValue={''}
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="**********"
                value={newPassword}
                onChange={changeNewPassword}
                defaultValue={''}
              />
            </div>
            <input type="checkbox" onClick={() => hidePassword()} /> Show
            Password
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={currentPassword && newPassword ? false : true}
              onClick={() => resetPassword()}
            >
              Change
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleCloseResetPassword()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END RESET PASSWORD */}
      </div>

      {/* FOOTER */}
      <footer className="text-center text-lg-start bg-light text-muted">
        <div
          className="text-center p-2"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        >
          Copyright &copy; 2023 Julie Sandlau. All rights reserved
        </div>
      </footer>
      {/* FOOTER */}
    </>
  );
}
