import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import {
  getListAllAdmin,
  createAdmin,
  deleteAdmin,
  updateAdmin,
  resetPasswordforSuperAdmin,
  resetPasswordbyIdAdmin,
} from "../../service/files";
import { API_LOCALHOST } from "../../service/config";
import { showNotification } from "../../service/notifications";

function AdminManagement() {
  const userLogin = JSON.parse(localStorage.getItem("usertoken"));
  if (!userLogin) {
    window.location.replace(`${API_LOCALHOST}admin/login`);
  }

  const [staffs, setStaffs] = useState({});
  const [lstStaff, setLstStaff] = useState([]);
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [id, setId] = useState();
  
  // SHOW VIEW USER
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = (u) => {
    setShowView(true);
    setStaffs(u);
  };
  // END SHOW VIEW USER

  
  /// RESET PASSWORD
  const [showResetPassword, setshowResetPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const handleCloseResetPassword = () => setshowResetPassword(false);
  const handleShowResetPassword = () => {
    setshowResetPassword(true);
  };

  const resetPassword = () => {
    resetPasswordforSuperAdmin(currentPassword, newPassword)
      .then((response) => {
        setshowResetPassword(false);
        showNotification("Update Password success", "success");
      })
      .catch((error) => {
        console.log(error);
        showNotification("Update Password fail", "danger");
      });
  };

  const changeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const changeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  // END RESET PASSWORD

  
  // RESET PASSWORD BY IDS
  const [showResetPasswordbyId, setshowResetPasswordbyId] = useState(false);
  const [newPasswordId, setNewPasswordId] = useState("");
  const handleCloseResetPasswordId = () => setshowResetPasswordbyId(false);
  const handleShowResetPasswordId = (user) => {
    setshowResetPasswordbyId(true);
    setId(user.id);
  };

  const resetPasswordbyIds = () => {
    resetPasswordbyIdAdmin(newPasswordId, id)
      .then((response) => {
        setshowResetPasswordbyId(false);
        showNotification("Update password success", "success");
      })
      .catch((error) => {
        console.log(error);
        showNotification("Update password fail", "danger");
      });
  };

  const changeNewPasswordbyId = (e) => {
    setNewPasswordId(e.target.value);
  };
  // END RESET PASSWORD BY ID


  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = lstStaff.slice(firstIndex, lastIndex);
  const npage = Math.ceil(lstStaff.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  // END PAGINATION

  useEffect(() => {
    const userLogin = JSON.parse(localStorage.getItem("usertoken"));
    if (!userLogin) {
      window.location.replace(`${API_LOCALHOST}admin/login`);
    }
    getData();
  }, []);

  // LIST STAFF
  const getData = () => {
    getListAllAdmin()
      .then((response) => {
        setLstStaff(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // END LIST

  // CREATE ADMIN
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const createStaff = () => {
    if (
      userName !== null &&
      userName !== undefined &&
      email !== null &&
      email !== undefined &&
      password !== null &&
      password !== undefined
    ) {
      createAdmin(userName, email, password)
        .then((response) => {
          setShow(false);
          if (response.code === 500) {
            showNotification(response.message, "warning");
          } else if (response.code === 0) {
            showNotification("Create user success", "success")
          }
          getData();
        })
        .catch((error) => {
          console.log(error);
          showNotification("Create Admin fail!", "danger");
        });
    }
  };
  // END CREATE ADMIN


  // SHOW/HIDE PASSWORD
  const [showPassword, setShowPassword] = useState(false);
  const hidePassword = () => {
    setShowPassword(!showPassword);
  };


  // UPDATE ADMIN
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = (staff) => {
    setShowUpdate(true);
    setUserName(staff.name);
    setEmail(staff.email);
    setPhone(staff.phone);
    setId(staff.id);
  };
  const updateStaffs = () => {
    if (
      userName !== null &&
      userName !== undefined &&
      email !== null &&
      email !== undefined
    ) {
      updateAdmin(userName, email, id)
        .then((response) => {
          setShowUpdate(false);
          showNotification("Update Admin success", "success");
        })
        .catch((error) => {
          console.log(error);
          showNotification("Update Admin fail!", "danger");
        });
    }
  };
  // END UPDATE ADMIN


  // SEARCH
  const [keySearch, setKeySearch] = useState();
  const handleSearch = () => {
    if (keySearch !== "" && keySearch !== undefined && keySearch !== null) {
      const lstStaffFilter = lstStaff.filter(
        (x) =>
          x.name.toLowerCase().includes(keySearch.toLowerCase()) ||
          x.email.toLowerCase().includes(keySearch.toLowerCase())
      );
      setLstStaff(lstStaffFilter);
    } else {
      getData();
    }
  };
  // END SEARCH


  // DELETE ADMIN
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (user) => {
    setShowDelete(true);
    setId(user.id);
  };

  const deleteStaffs = () => {
    deleteAdmin(id)
      .then((response) => {
        setShowDelete(false);
        showNotification("Delete Admin success", "success");
        getData();
      })
      .catch((error) => {
        console.log(error);
        showNotification("Delete Admin Fail!", "danger");
      });
  };

  // LOGOUT
  const Logout = () => {
    localStorage.clear();
    showNotification("Logout success", "success");
    window.location.replace(`${API_LOCALHOST}admin/login`);
  };

  return (
    <>
      <div className="container" style={{ minHeight: "100vh" }}>
        <h2>Manage Admin</h2>
        <div className="row col-md-auto">
          <div className="col-md-8">
            <button className="btn btn-success" onClick={() => handleShow()}>
              <i className="fa fa-plus"></i> Add Admin
            </button>
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-info"
              style={{ marginRight: "5px" }}
              onClick={() => handleShowResetPassword()}
            >
              Reset Password
            </button>
            <button className="btn btn-secondary" onClick={() => Logout()}>
              Sign Out <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </div>
        
        {/* START HANDLE SEARCH */}
        <div className="row col-md-auto mt-3">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <div className="form-inline my-2 my-lg-0">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => setKeySearch(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  } else {
                    getData();
                  }
                }}
              />
              <button
                className="btn btn-outline-info my-2 my-sm-0"
                type="button"
                onClick={() => handleSearch()}
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        {/* END HANDLE SEARCH */}

        <div className="tab-content">
          <table className="table bordered mt-4">
            <thead className="thead-light">
              <tr>
                <th scope="col">ID</th>
                <th scope="col-2">Username</th>
                <th scope="col-2">Email</th>
                <th scope="col-2">Update</th>
                <th scope="col-2">Delete</th>
                <th scope="col-2">Reset Password</th>
              </tr>
            </thead>
            <tbody>
              {records ? (
                records.map((u, index) => (
                  <tr key={u.id}>
                    <td>{(currentPage - 1) * 5 + (index + 1)}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShowView(u)}
                    >
                      {u.name}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShowView(u)}
                    >
                      {u.email}
                    </td>
                    <td>
                      <i
                        className="fa fa-edit"
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => handleShowUpdate(u)}
                      ></i>
                    </td>
                    <td>
                      <i
                        className="fa fa-remove"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleShowDelete(u)}
                      ></i>
                    </td>
                    <td 
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleShowResetPasswordId(u)}
                    >
                      <i className="fa fa-key"></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
          {/* START PAGINATION */}
          <nav>
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <a href="#" className="page-link" onClick={() => prePage()}>
                  Prev
                </a>
              </li>
              {numbers.map((n, i) => (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <a
                    href="#"
                    className="page-link"
                    onClick={() => changePage(n)}
                  >
                    {n}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a href="#" className="page-link" onClick={() => nextPage()}>
                  Next
                </a>
              </li>
            </ul>
          </nav>
          {/* END PAGINATION */}
        </div>

        {/* START ADD USER */}
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: "left" }}
                placeholder="Username"
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                style={{ textAlign: "left" }}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                style={{ textAlign: "left" }}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                style={{ right: "50px", top: "83%" }}
                onClick={() => hidePassword()}
              >
                {showPassword ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={(userName && email && password) ? false : true } onClick={() => createStaff()}>
              Save
            </Button>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END ADD USER  */}
        
        {/* START SHOW VIEW USER */}
        <Modal show={showView} onHide={handleCloseView} animation={false}>
          <Modal.Header>
            <Modal.Title>View Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-3">Username:</div>
              <div className="col-3">{staffs.name}</div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-3">Email:</div>
              <div className="col-3">{staffs.email}</div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-3">Gender:</div>
              <div className="col-3">{staffs.gender}</div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-3">Phone:</div>
              <div className="col-3">{staffs.phone}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleCloseView()}
              style={{ backgroundColor: "red" }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END SHOW VIEW USER */}

        {/* START SHOW UPDATE */}
        <Modal show={showUpdate} onHide={handleCloseUpdate} animation={false}>
          <Modal.Header>
            <Modal.Title>Update Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>UserName</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: "left" }}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                style={{ textAlign: "left" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => updateStaffs()}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => handleCloseUpdate()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END SHOW UPDATE USER */}
        

        {/* START SHOW DELETE USER */}
        <Modal show={showDelete} onHide={handleCloseDelete} animation={false}>
          <Modal.Header>
            <Modal.Title>Delete Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete ?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-danger"
              onClick={() => deleteStaffs()}
            >
              Delete
            </Button>
            <Button variant="secondary" onClick={() => handleCloseDelete()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END SHOW DELETE USER */}

        {/* START SHOW RESET PASSWORD */}
        <Modal
          show={showResetPassword}
          onHide={handleCloseResetPassword}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>ResetPassword</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Current Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="***************"
                value={currentPassword}
                onChange={changeCurrentPassword}
                defaultValue={""}
              />
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="****************"
                value={newPassword}
                onChange={changeNewPassword}
                defaultValue={""}
              />
            </div>
            <input type="checkbox" onClick={() => hidePassword()} /> Show
            Password
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => resetPassword()}>
              Change
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleCloseResetPassword()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END SHOW RESET PASSWORD */}


        {/* START RESET PASSWORD BY IDS */}
        <Modal
          show={showResetPasswordbyId}
          onHide={handleCloseResetPasswordId}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>New Password</label>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="**************"
                value={newPasswordId}
                onChange={changeNewPasswordbyId}
                defaultValue={""}
              />
            </div>
            <input type="checkbox" onClick={() => hidePassword()} /> Show
            Password
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={newPasswordId ? false : true}
              onClick={() => resetPasswordbyIds()}
            >
              Change
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleCloseResetPasswordId()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END RESET PASSWORD BY IDS */}
      </div>

      <footer className="text-center text-lg-start bg-light text-muted">
        <div
          className="text-center p-2"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            justifyContent: "center",
          }}
        >
          Copyright © 2023 Julie Sandlau. All rights reserved
        </div>
      </footer>
    </>
  );

  //
  function prePage() {
    if (0 !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changePage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== lastIndex && currentPage + 1 <= numbers.length) {
      setCurrentPage(currentPage + 1);
    }
  }
}

export default AdminManagement;
