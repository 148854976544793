import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  createFolder,
  deleteFolder,
  downloadFolder,
  getListpath,
  updateFolder,
  uploadFile,
  uploadMultipleFile,
} from '../../service/files';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { API_LOCALHOST, PATH_URL } from '../../service/config';
import '../../access/main.css';
import { showNotification } from '../../service/notifications';

export default function SubFolder() {
  const nameFolder = useParams();
  const [fileUploads, setFileUploads] = useState();
  const [folder, setFolder] = useState([]);
  const [subFolderName, setSubFolderName] = useState([]);
  const [oldNameFolder, setOldNameFolder] = useState([]);
  const [paths, setPaths] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const navigate = useNavigate();

  const getSubFolder = () => {
    const link = localStorage.getItem('path');
    let users = JSON.parse(localStorage.getItem('usertoken'));
    const path = link.substring(
      link.indexOf('/' + users.id + '/') + (users.id.toString().length + 1),
      link.length
    );
    setPaths(path);
    getListpath(path)
      .then((response) => {
        setFolder(response.data);
      })
      .catch((error) => {
        console(error);
      });
  };

  useEffect(() => {
    const userLogin = JSON.parse(localStorage.getItem('usertoken'));
    if (!userLogin) {
      window.location.replace(`${API_LOCALHOST}`);
    }
    setPaths(localStorage.getItem('path'));
    getSubFolder();
  }, []);

  // CREATE SUBFOLDER
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const createSubFolder = () => {
    if (paths) {
      createFolder(subFolderName, paths)
        .then((response) => {
          setShow(false);
          showNotification('Create folder success!', 'success');
          getSubFolder();
        })
        .catch((error) => {
          console.log(error);
          showNotification('Create folder fail!', 'danger');
        });
    }
  };
  // CREATE SUBFOLDER

  // UPDATE SUBFOLDER
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);

  const handleShowUpdate = (f) => {
    setOldNameFolder(f.name);
    setSubFolderName(f.name);
    setShowUpdate(true);
  };
  const updateSubFolder = () => {
    if (subFolderName !== null && subFolderName !== undefined) {
      updateFolder(paths + '/' + oldNameFolder, paths + '/' + subFolderName)
        .then((response) => {
          setOldNameFolder();
          setShowUpdate(false);
          showNotification('Update folder success!', 'success');
          getSubFolder();
        })
        .catch((error) => {
          console.log(error);
          showNotification('Update folder fail!', 'danger');
        });
    }
  };
  // UPDATE SUBFOLDER

  const subFolder = (f) => {
    if (f.isFolder) {
      window.location.replace('/sub_folder/' + f.name);
      localStorage.setItem('path', f.path);
    } else window.open(f.path);
  };

  const changeNameFolder = (event) => {
    setSubFolderName(event.target.value);
  };

  // DELETE SUBFOLDER
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const handleShowDelete = (name) => {
    setShowDelete(true);
    setPaths(name);
  };
  const deleteSubFolderFile = () => {
    deleteFolder([paths])
      .then((response) => {
        setShowDelete(false);
        getSubFolder();
        console.log('run in hrere');
        showNotification('Delete folder success', 'success');
      })
      .catch((error) => {
        console.log(error);
        showNotification('Delete folder fail', 'danger');
      })
      .finally(getSubFolder());
  };
  // DELETE SUBFOLDER

  // ZIP FILE
  const zipFile = async () => {
    let listzip = folder.filter((name) => name.isChecked === true);
    let result = await downloadFolder(listzip);
    downloadFileZip(result.data.path, result.data.name);
  };
  // ZIP FILE
	const [showDownloadProgress, setshowDownloadProgress] = useState(false);
  const handleShowDownload = () => setshowDownloadProgress(true);
  const handleCloseDownload = () => setshowDownloadProgress(false);
  // DOWNLOAD FILE
  const downloadFile = (f) => {
    fetch(f.path, {
      method: 'GET',
      // mode: 'no-cors',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', f.name); //or any other extension
          document.body.appendChild(link);
          link.click();
					setshowDownloadProgress(false);
          showNotification('Download file successfully!', 'success');
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // DOWNLOAD FILE

  // DOWNLOAD ALL FILE
  const downloadAll = async () => {
    const path = localStorage.getItem('path');
    const a = path.substring(path.lastIndexOf('/') + 1, path.length);
    let lstDownload = [
      {
        path: path,
        name: a,
        isFolder: true,
      },
    ];
    let result = await downloadFolder(lstDownload);
    downloadFileZip(result.data.path, result.data.path);
  };

  // DOWNLOAD ALL FILE

  const downloadFileZip = (path, name) => {
    fetch(path, {
      method: 'GET',
      // mode: 'no-cors',
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name); //or any other extension
          document.body.appendChild(link);
          link.click();
					setshowDownloadProgress(false);
					showNotification('Download file successfully!', 'success');
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // deleteFolder([{path:path}])
        getSubFolder();
      });
  };

  // GO BACK
  const goBack = () => {
    const link = localStorage.getItem('path');
    const path = link.substring(link.lastIndexOf('/'), -1);
    const a = path.substring(path.lastIndexOf('/') + 1, path.length);
    let users = JSON.parse(localStorage.getItem('usertoken'));
    if (path === PATH_URL + users.id) {
      navigate('/upload');
    } else {
      localStorage.setItem('path', path);
      window.location.replace('/sub_folder/' + a);
    }
  };
  // GO BACK

  // LOGOUT
  const logout = () => {
    localStorage.clear();
    showNotification('Logout success', 'success');
    window.location.replace('/');
  };
  // LOGOUT

  // UPLOAD MULTIPLE FILES
  const [showMutipleUpload, setShowMutipleUpload] = useState(false);
  const handleClosePopupUpload = () => setShowMutipleUpload(false);
  const handleShowPopupUpload = () => setShowMutipleUpload(true);
  const uploadMultipleFiles = () => {
    setIsUpload(true);
    if (!isUpload) {
      uploadMultipleFile(fileUploads, paths)
        .then((response) => {
          setShowMutipleUpload(false);
          getSubFolder();
          setIsUpload(false);

          setFileUploads(null);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(getSubFolder());
    }
  };

  const chooseUploadFile = (e) => {
    setFileUploads(e.target.files);
  };
  // UPLOAD MULTIPLE FILES

  // SELECTED
  const [isCheck, setIsCheck] = useState(false);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'allSelect') {
      let tempItem = folder.map((filename) => {
        return { ...filename, isChecked: checked };
      });
      setIsCheck(true);
      setFolder(tempItem);
    } else {
      let tempItem = folder.map((filename) => {
        if (filename.name === name) {
          return {
            ...filename,
            isChecked: checked,
          };
        }
        return {
          ...filename,
        };
      });
      let flag = 0;
      tempItem.map((item) => {
        if (item.isChecked) {
          flag = 1;
        }
      });
      if (!flag) {
        setIsCheck(false);
      } else {
        setIsCheck(true);
      }
      setFolder(tempItem);
    }
  };
  // SELECTED

  // DELETE SELECT
  const [showDeleteSelect, setShowDeleteSelect] = useState(false);
  const handleCloseDeleteSelect = () => setShowDeleteSelect(false);
  const handleShowDeleteSelect = (names) => {
    setShowDeleteSelect(true);
    setPaths(names);
  };

  const handleDeleteSelect = () => {
    let checkeddelete = 0;
    let iDelete = 0;
    folder.forEach((item) => {
      if (item.isChecked === true) {
        iDelete += 1;
      }
    });
    let listDelete = folder.filter((item) => {
      return item.isChecked === true;
    });
    if (listDelete.length > 0) {
      deleteFolder(listDelete)
        .then((response) => {
          setShowDeleteSelect(false);
          showNotification('Delete folder success!', 'success');
          getSubFolder();
        })
        .catch((error) => {
          showNotification('Delete folder fail!', 'danger');
          getSubFolder();
        });
    }
  };
  // DELETE SELECTED

  // DELETE ALL FILE
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const handleCloseDeleteAll = () => setShowDeleteAll(false);
  const handleShowDeleteAll = (folder) => {
    setShowDeleteAll(true);
    setPaths(folder);
  };

  const deleteAll = () => {
    let lstDelete = [];
    folder.forEach((e) => {
      const item = {
        path: e.path,
      };
      lstDelete.push(item);
    });
    console.log(lstDelete);
    deleteFolder(lstDelete)
      .then((response) => {
        setTimeout(function () {
          window.location.reload();
        }, 500);
        setShowDeleteAll(false);
        showNotification('Delete File success', 'success');
        getSubFolder();
      })
      .catch((error) => {
        console.log(error);
        showNotification('Delete File fail!', 'danger');
      });
  };
  // DELETE ALL FILE

  return (
    <>
      <div className="container mt-5" style={{ minHeight: '100vh' }}>
        {/* Header */}
        <div className="row col-md-auto">
          <div className="col-sm-8">
            <button className="btn btn-primary" onClick={() => handleShow()}>
              New folder
            </button>
            <button
              className="btn btn-success ml-2"
              onClick={() => handleShowPopupUpload()}
            >
              Upload File
            </button>
            {isCheck && (
              <button
                className="btn btn-danger ml-2"
                onClick={() => handleShowDeleteSelect(folder)}
                hidden={
                  !folder.some((filename) =>
                    filename.isChecked === true ? true : false
                  )
                }
              >
                Delete Selected
              </button>
            )}
            {isCheck && (
              <button
                className="btn btn-infomation ml-2"
                onClick={() => {zipFile(); handleShowDownload()}}
                hidden={
                  !folder.some((filename) =>
                    filename.isChecked === true ? true : false
                  )
                }
              >
                Download Selected
              </button>
            )}
            {!isCheck && (
              <button
                className="btn btn-danger ml-2"
                onClick={() => handleShowDeleteAll()}
              >
                Delete All
              </button>
            )}
            {!isCheck && (
              <button
                className="btn btn-infomation ml-2"
                onClick={() => downloadAll()}
              >
                Download All
              </button>
            )}
          </div>
          <div className="col-sm-4" style={{ textAlign: 'right' }}>
            <button className="btn btn-secondary" onClick={() => logout()}>
              Sign out <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </div>
        <table className="table bordered mt-4">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="allSelect"
                  checked={
                    !folder.some((filename) => filename?.isChecked !== true)
                  }
                  onChange={handleChange}
                />
              </th>
              <th scope="col">ID</th>
              <th scope="col-2">Name</th>
              <th scope="col-2" style={{ textAlign: 'center' }}>
                Download Count
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {folder.map((f, index) => (
              <tr key={f.id}>
                <td>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={f.name}
                    checked={f?.isChecked || false}
                    onChange={handleChange}
                  />
                </td>
                <td scope="row">{index + 1}</td>
                <td
                  scope="row"
                  onDoubleClick={() => subFolder(f)}
                  style={{ cursor: 'pointer' }}
                >
                  {f.isFolder === true ? (
                    <i className="fa-regular fafolder"></i>
                  ) : (
                    <i className="fa fa-paperclip"></i>
                  )}{' '}
                  {f.name}
                </td>
                <td
                  scope="row"
                  style={{
                    textAlign: 'center',
                    color: 'green',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  {f.download_count}
                </td>
                <td>
                  {f.isFolder === true ? (
                    <i
                      className="fa fa-edit"
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={() => handleShowUpdate(f)}
                    ></i>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {f.isFolder === false ? (
                    <i
                      className="fa fa-download"
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={() => downloadFile(f)}
                    ></i>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <i
                    className="fa fa-trash"
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => handleShowDelete(f)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row col-md-auto">
          <button
            className="btn btn-primary"
            style={{ marginRight: '5px' }}
            onClick={() => goBack()}
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        {/* START CREATE FOLDER */}
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header>
            <Modal.Title>Create Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name Folder</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'left' }}
                name="nameFolder"
                id="nameFolder"
                onChange={changeNameFolder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={folder ? false : true}
              onClick={() => createSubFolder()}
            >
              Save
            </Button>
            <Button variant="secondary" onClick={() => handleClose()}>
              <i></i>Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END CREATE FOLDER */}

        {/* START UPDATE FOLDER */}
        <Modal show={showUpdate} onHide={handleCloseUpdate} animation={false}>
          <Modal.Header>
            <Modal.Title>Update Folder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Name Folder</label>
              <input
                type="text"
                className="form-control"
                style={{ textAlign: 'left' }}
                value={subFolderName}
                name="nameFolder"
                id="nameFolder"
                onChange={changeNameFolder}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => updateSubFolder()}>
              Update
            </Button>
            <Button variant="secondary" onClick={() => handleCloseUpdate()}>
              <i></i> Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END UPDATE FOLDER */}

        {/* START DELETE FOLDER AND FILE */}
        <Modal show={showDelete} onHide={handleCloseDelete} animation={false}>
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ backgroundColor: 'red' }}
              onClick={() => deleteSubFolderFile()}
            >
              Delete
            </Button>
            <Button variant="secondary" onClick={() => handleCloseDelete()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END DELETE FOLDER AND FILE */}

        {/* START DELETE SELECTED */}
        <Modal
          show={showDeleteSelect}
          onHide={handleCloseDeleteSelect}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-danger"
              onClick={() => handleDeleteSelect()}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleCloseDeleteSelect()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END DELETE SELECT */}

        {/* START DELETE ALL  */}
        <Modal
          show={showDeleteAll}
          onHide={handleCloseDeleteAll}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Are you sure you want to delete ?</h2>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-danger" onClick={() => deleteAll()}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => handleCloseDeleteAll()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* START UPLOAD MULTIPLE FILES */}
        <Modal
          show={showMutipleUpload}
          onHide={handleClosePopupUpload}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Upload Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form method="POST" enctype="multipart/form-data">
              <label for="html-files" style={{ width: '100%' }}>
                Select Files Maximum 2GB:
              </label>
              <input
                type="file"
                id="html-files"
                name="html-files[]"
                multiple
                onChange={(e) => chooseUploadFile(e)}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={fileUploads ? false : true}
              onClick={() => uploadMultipleFiles()}
            >
              {isUpload ? 'Uploading...' : 'Upload'}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setShowMutipleUpload(false)}
            >
              <i></i>Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* END UPLOAD MULTIPLE FILES */}

				{/* START DOWNLOAD MESSAGE */}
        <Modal
          show={showDownloadProgress}
          onHide={handleCloseDownload}
          animation={true}
        >
          <Modal.Header>
            <Modal.Title>Downloading Files...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src='https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif' style={{width: '100px', height: '100px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} alt='download loading' />
              <label for="html-files" style={{ width: '100%' }}>
                Download in progress. Please wait a minute...
              </label>
              
          </Modal.Body>
        </Modal>
        {/* END DOWNLOAD MESSAGE */}
      </div>

      {/* FOOTER */}
      <footer className="text-center text-lg-start bg-light text-muted">
        <div
          className="text-center p-2"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        >
          Copyright &copy; 2023 Julie Sandlau. All rights reserved
        </div>
      </footer>
      {/* FOOTER */}
    </>
  );
}
